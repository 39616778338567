<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, Stat, VclList },
  data() {
    return {
      statData: null,

      subscriber: null,

      table: {
        heade: [
          "Usuário",
          "Nome",
          "Pedido",
          "Data",
          "Valor",
          "Nível",
          "%",
          "Bonus",
        ],
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getSubscriber() {
      api.get("tv/subscribers").then((response) => {
        if (response.data.status == "success") {
          this.subscriber = response.data.subscriber;

          if (
            response.data.subscriber.date &&
            response.data.subscriber.due_date
          ) {
            this.statData = [
              {
                title: "Plano",
                value: response.data.subscriber.plan,
              },
              {
                title: "Status",
                value: response.data.subscriber.status,
              },
              {
                title: "Dia do Vencimento",
                value: response.data.subscriber.due_date,
              },
            ];
          } else {
            this.statData = [
              {
                title: "Plano",
                value: response.data.subscriber.plan,
              },
              {
                title: "Status",
                value: response.data.subscriber.status,
              },
            ];
          }
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          icon: "monthly",
          title: "Status",
          value: this.subscriber.status,
        },
      ];
    },
    getMonthlies() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("tv/subscribers/monthlies")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getSubscriber();
    this.getMonthlies();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Minha Assinatura") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!subscriber" class="text-center">
      <b-spinner small variant="default"></b-spinner>
    </div>
    <div
      v-else-if="subscriber && subscriber.status == 'empty'"
      class="bg-soft-light rounded p-4 text-center"
    >
      <i class="far fa-sad-cry font-size-24"></i><br />
      você ainda não possui um plano de assinatura<br /><br />
      <router-link tag="a" to="/tv/plans" class="btn btn-default btn-lg">
        Contratar
      </router-link>
    </div>

    <div v-else class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-4 col-lg-4 col-xl-4"
      >
        <Stat :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div v-if="subscriber && subscriber.username && subscriber.password">
      <div class="divisor mb-3">
        <span>Acesso a TV</span>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <Stat title="Usuário" :value="subscriber.username" />
        </div>
        <div class="col-md-4">
          <Stat title="Senha" :value="subscriber.password" />
        </div>
      </div>
    </div>

    <div v-if="subscriber && table.body && table.body.length > 0" class="row">
      <div class="col-lg-12">
        <div class="divisor mb-3">
          <span>Mensalidades</span>
        </div>
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>Código</th>
                    <th>Vencimento em</th>
                    <th>Pagamento em</th>
                    <th>Ativo até</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td class="notranslate">{{ td.id.split("-")[0] }}</td>
                    <td class="notranslate">
                      {{ td.date.due }}
                    </td>
                    <td class="notranslate">
                      <template v-if="td.status === 'paid'">{{
                        td.date.payment
                      }}</template>
                      <template v-else>–</template>
                    </td>
                    <td class="notranslate">
                      <template v-if="td.status === 'paid'">{{
                        td.date.end
                      }}</template>
                      <template v-else>–</template>
                    </td>
                    <td class="notranslate">
                      <span
                        v-if="td.status === 'pending'"
                        class="badge badge-soft-warning font-size-12"
                        >Aguardando pagamento</span
                      >
                      <span
                        v-else-if="td.status === 'paid'"
                        class="badge badge-soft-success font-size-12"
                        >Pago</span
                      >
                    </td>
                    <td class="text-right">
                      <router-link
                        tag="a"
                        :to="'/store/order/' + td.order.id"
                        class="btn btn-default btn-sm"
                      >
                        Ver
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
  margin-bottom: 2px;
}
.divisor span {
  display: inline-block;
  padding: 5px 10px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
</style>
